<template lang="pug">
    div
        h2 Error 404! Not found
</template>

<script>
    export default {
        name: 'NotFound',
    }
</script>

<style scoped>
  h2 {
    text-align: center;
    font-size: 2em;
  }
</style>
